export enum AppRoutes {
  NO_MATCH = '*',
  MAIN = '/*',
  LEGAL = 'legal/*',
  QR = 'qr/*',
  POST_REDIRECT = 'post-redirect',
  REDIRECT = 'redirect/:operation',
  SIGNIN = 'signin',
  SIGNUP = 'signup/*',
  SIGNUP_GLOBAL = 'signup-global/*',
  VERIFICATION = 'verification',
  MOBILE_APP_INFO = 'mobile-app-info',
  CPD_SIGNING = 'cpd-signing',
}

export interface MobileAppInfoPageState {
  routeName: string;
}


export const MobileAppRedirects = [
  'communities',
  'community',
  'community-comment',
  'community-invite',
  'community-metrics',
  'community-post',
  'merchant-login',
  'profile',
  'referral-signup',
  'toco-near',
];